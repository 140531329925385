<template>
  <div>
    <v-form>
      <p class="mb-3">Examine the IR spectrum provided for compound C.</p>
      <p class="mb-3">
        The peaks below 1500 cannot be used to make any structural assignments because they are in
        the fingerprint region. There are a few important peaks in this IR spectrum. What is each
        peak most likely due to?
      </p>
      <p class="mb-5">
        Hint: the large peak that stretches from 2500-3500 is actually two peaks: there is a sharp
        peak around 3000 that is mostly hidden.
      </p>

      <p class="mb-3">
        What is peak 1 around
        <chemical-latex content="\text{3500-2500 cm}^{-1}" />
        most likely due to?
      </p>
      <v-radio-group v-model="inputs.studentAnswerA" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-3">
        What is peak 2 around
        <chemical-latex content="\text{2950 cm}^{-1}" />
        most likely due to?
      </p>
      <v-radio-group v-model="inputs.studentAnswerB" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LBA2_M3Q2a',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
      },
      options: [
        {
          text: '$\\ce{O-H or N-H}$',
          value: 'OHorNH',
        },
        {
          text: '$\\ce{C-H}(sp^3)$',
          value: 'CHsp3',
        },
        {
          text: '$\\ce{C-H}(sp^2)$',
          value: 'CHsp2',
        },
        {
          text: '$\\ce{C-H}(sp)$',
          value: 'CHsp',
        },
        {
          text: '$\\ce{C-O or C-N}$',
          value: 'COorCN',
        },
        {
          text: '$\\ce{C=O}$',
          value: 'CO',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
